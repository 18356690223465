.select-language {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #2b4e73;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    width: 40rem;
    margin: 10px;
    align-items: center;
}

.select-language select {
    font-size: xx-large;
}

.flag {
    cursor: pointer;
    height: 40px;
    width: 80px;
    padding: 10px;
}

.flag-box {
    width: 25rem;
}

@media screen and (max-width: 768px) {
    .select-language {
        font-size: large;
        width: 12rem;
        flex-direction: column;
        align-items: center;
    }

    .select-language select {
        font-size: large;
    }

    .flag-box {
        display: flex;
        flex-direction: column;
        width: auto;
    }
}

