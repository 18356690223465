.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 50px;
    color: #02499d;
    font-size: xx-large;
    background-color: rgba(99, 12, 12, 0.3); /* Gimp, color of 'Beautiful Love' */
    margin: auto;
    width: 45rem;
}

.login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-inputset {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2 {
    font-size: 24px;
    color: #007bff;
    text-align: center;
    margin-bottom: 20px;
}

.themessage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    width: 30rem;
}

.supportRequest {
    display: flex;
    color: #007bff;
    text-decoration: none;
    font-size: large;
    padding: 20px;
}

.supportRequest:hover {
    color: #0056b3;
    text-decoration: underline;
}

/* Buttons and Links */
@media screen and (max-width: 768px) {

    .login-container {
        width: 17rem;
        font-size: large;
        padding: 10px;
    }

    h2 {
        font-size: 14px;
    }

    input, button {
        font-size: large;
        width: 12rem;
        border-radius: 4px;
    }

    .themessage {
        font-size: large;
        padding: 10px;
        width: 16rem;
    }

    .login-inputset i {
        width: 30px;
    }
}
