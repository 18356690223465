.welcomeGraphic {
    width: 50%;
}

.small {
    font-size: 14pt;
    fill: darkblue;
    opacity: 0.5;
}

.medium {
    font-size: 18pt;
    fill: #c06767;
    opacity: 0.5;
}

.large {
    font-size: 22pt;
    fill: #ab9211;
    opacity: 0.5;
}

@media (width <= 768) {
    .welcomeGraphic {
        width: 100%;
    }

}
