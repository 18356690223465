.handbook-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding: 50px;
    color: #02499d;
    font-size: large;
    background-color: rgba(250, 100, 255, 0.44);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    margin: auto;
    width: 800px;
}