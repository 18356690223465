.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #007bff;
    padding: 10px;
    align-items: center;
}

.header-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header-picture {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #007bff;
    padding: 10px;
}

.footerLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #007bff;
}

.footButton {
    width: 30%;
}

@media screen and (max-width: 768px) {
    .footButton {
        width: 40%;
    }
}