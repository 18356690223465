.App {
    text-align: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

body {
    position: relative;
    margin: 0;
    height: 100vh;
    background-image: url('../images/thecross test.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.app-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30px;
}

button {
    padding: 10px;
    background-color: #02499d;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: xx-large;
    margin: 10px;
    width: 40rem;
}

button:hover {
    background-color: #0056b3;
}

button:disabled {
    background-color: #c8c8c8;
}

a {
    display: block;
    text-align: center;
    margin-top: 20px;
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

input {
    padding: 10px;
    border: 2px solid;
    border-radius: 10px;
    box-sizing: border-box;
    font-size: xx-large;
    margin: 10px;
    width: 40rem;
}

.infocolumn {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 10px;
    justify-content: space-around;
    align-items: center;
    height: 20vh;
}

.resetPWD {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 10px;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    background-color: #f2f2f2;
}

.buttonset {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
}

.verse-display {
    display: flex;
    flex-direction: column;
    color: #555;
    text-align: left;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    width: 90%;
}

.theverse {
    padding: 10px;
}

.question-list {
    display: flex;
    flex-direction: column;
    color: #555;
    text-align: left;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    width: 90%;
}

.new-question {
    width: 90%;
}


.deleteQuestionButton {
    width: 32px;
    background-color: #f2f2f2;
    margin: 10px;
}

.question {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.question button {
    width: 60px;
}

.video-link {
    width: 90%;
    padding: 10px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.textarea-container {
    position: relative;
    width: 80%;
    padding-top: 20px;
}

.label-comment {
    background-color: #fff;
    padding: 5px;
}

textarea {
    width: 100%;
    font-size: xx-large;
}

.modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    text-align: left ;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    width: 2rem;
}

.close:hover,
.close:focus {
    color: #00ff00;
    text-decoration: none;
    cursor: pointer;
}

.return {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    width: 2rem;
}

.return:hover,
.return:focus {
    color: #ff0000;
    text-decoration: none;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    textarea {
        font-size: medium;
    }

    body {
        background-size: cover; /* Adjust as needed for smaller screens */
    }
}

@media screen and (max-width: 480px) {
    body {
        background-size: 100% 100%; /* Ensure full coverage on very small screens */
    }
}
