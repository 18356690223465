/* toastify-custom.css */

.Toastify__toast {
    font-family: Arial, sans-serif;
    font-size: 20px;
    padding: 8px 16px;
    border-radius: 8px;
}

.Toastify__toast--success {
    --toastify-icon-color-success: #fae201;
    background-color: #4caf50;
    color: #fff;
}

.Toastify__toast--error {
    --toastify-icon-color-error: #fc9167;
    background-color: #f44336;
    color: #fff;
}

.Toastify__toast--info {
    --toastify-icon-color-info: #47fa03;
    background-color: #2196f3;
    color: #fff;
}

.Toastify__close-button {
    color: #fff;
    opacity: 0.7;
    padding: 0;
    margin-right: 8px;
    font-size: 20px;
    width: 30px;
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
    opacity: 1;
}

.Toastify__toast-body {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.Toastify__progress-bar {
    background: rgba(255, 255, 255, 0.7);
}

.Toastify__toast-icon {
    margin-right: 8px;
}

@media screen and (max-width: 768px) {
    .Toastify__toast {
        font-size: 14px;
        border-radius: 0;
    }

    .Toastify__close-button {
        font-size: 16px;
    }
}